@import '@odx/ui/core-theme.css'
@import '@odx/icons/core/core-icons.css'
@import '@odx/icons/safety/safety-icons.css'
@import '@odx/icons/uib-legacy/uib-legacy-icons.css'
@import url('https://fonts.googleapis.com/icon?family=Material+Icons')
@import 'assets/styles/variables'
@import 'src/assets/styles/mixins'
@import 'src/assets/styles/tooltip'
@import 'src/assets/styles/prime-ng-table'
@import 'src/assets/styles/prime-ng-dropdown'
@import 'bootstrap/scss/bootstrap'
@import "primeng/resources/primeng.min.css"
@import "primeng/resources/themes/saga-blue/theme.css"
@import "primeicons/primeicons.css"

body
  color: var(--odx-c-text) !important
  font-size: 14px !important
  background-color: var(--odx-c-background) !important
  overflow-wrap: break-word !important

html, body
  display: flex
  flex-direction: column
  height: 100vh
  overflow: hidden

.button-text
  margin-bottom: 0 !important

.top-dd
  margin-top: -7px

.bottom-dd
  margin-top: 7px

.c-form-wrapper
  min-height: 100%
  padding-bottom: 20px

.loading-container
  height: 100vh
  width: 100vw
  display: -ms-flexbox
  display: flex
  -ms-flex-pack: center
  justify-content: center
  -ms-flex-align: center
  align-items: center
  position: absolute
  z-index: 999

.c-create-station__wrapper-height
  display: flex
  flex-direction: column
  flex-grow: 1

.c-create-update-wrapper
  @include tabs-wrapper
  flex-grow: 1

.c-email-cursor
  cursor: pointer!important

.c-mailto-link
  width: inherit
  height: 32px
  position: absolute

.category-tooltip
  top: -4px
  right: 0
  left: 0
  &::after
    bottom: 0!important
  &::before
    bottom: 0!important

.c-form-footer__buttons
  margin-top: 0.5rem

.tooltip
  position: fixed
  background-color: rgba(15, 20, 25, .9)
  color: $steelwhite-01
  padding: 8px
  display: block
  pointer-events: none
  line-height: 1.2
  opacity: 1 !important

.tooltip::after
  content: ""
  position: absolute
  top: 100%
  left: 50%
  margin-left: -5px
  border-width: 5px
  border-style: solid
  border-color: rgba(15, 20, 25, .9) transparent transparent transparent

.navigation-back
  color: $steelwhite-01

.software-version-col
  top: -15px
  right: -15px

.software-version
  font-size: 14px

::-webkit-scrollbar:horizontal
  border: none

.form-control
  border-radius: 0px !important

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext
  background-color: $steelgray-04
  border: none
  border-radius: 0px
  color: $steelwhite-01

.multiselect-filter
  width: 100%
  height: 32px !important
  .p-dropdown
    width: 100%

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active
  -webkit-box-shadow: 0 0 0 30px $steelgray-04 inset !important
  -webkit-text-fill-color: $steelwhite-01
  caret-color: $steelwhite-01

::ng-deep .multiselect-filter
  width: 100%
  height: 32px !important

.p-multiselect
  background-color: $steelgray-05
  border: none
  border-radius: 0
  width: 100% !important
  height: 32px !important

.p-multiselect .p-multiselect-label.p-placeholder
  color: $steelgray-02
  height: 32px !important
  display: flex
  align-items: center
  padding-left: 10px

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus
  background: none

.p-multiselect-label
  padding: 0.5rem 0.5rem
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s

.p-multiselect-label-container
  display: block
.filters
  .p-multiselect-panel
    width: 100% !important
    max-width: 260px

    .p-checkbox
      margin-right: 0.5rem
.p-dropdown
  display: inline-flex
  cursor: pointer
  position: relative
  -webkit-user-select: none
  user-select: none

.ellipsis-text
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.hide-element
  display: none

.tabbar
  width: 100%
  display: grid
  grid-template-columns: 80% 20%
  border-bottom: 1px solid rgba(0,0,0,.4)

.column-filter
  padding: 0 15px
  .multiselect-float
    float: right
    width: 250px
    margin: auto 0
    .p-multiselect-label-container
      width: 90%

.mt-2
  margin-top: 0.5rem !important

.w-100
  width: 100% !important

.w-50
  width: 50% !important

.p-tooltip-text
  padding: 0.3rem 0.5rem !important

.p-inputtext
  padding: 0.5rem 0.5rem

:root
  --odx-v-scrollbar-thumb-color: var(--odx-c-separator)
  --odx-v-scrollbar-thumb-color-hover: var(--gray-400)
  --odx-c-highlight: #0074FF
  --odx-c-highlight-hover: #0066e0 !important
  --odx-c-highlight-disabled: #0069E6
  --odx-bar-background-color: var(--primary-color)

.confirm_footer
  flex-direction: row-reverse
  align-items: flex-end

.odx-main-menu__inner
  background-color: var(--odx-c-background-content)

.odx-main-menu__footer > .odx-text, .odx-main-menu__footer > .odx-main-menu__info
  padding-top: calc(var(--odx-vertical-rythm-base-size)* 0.5)

.odx-menu > .odx-action-group
  width: max-content

.p-multiselect-panel .p-multiselect-header .p-multiselect-close
  margin-left: 0.5rem
  width: 2rem
  height: 2rem
  color: #6c757d
  border: 0 none
  background: transparent
  border-radius: 50%
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s

.p-multiselect-panel .p-multiselect-header
  padding: 0.5rem 1rem
  border-bottom: 0 none
  margin: 0
  border-top-right-radius: 3px
  border-top-left-radius: 3px

.p-dropdown-header
  background: #465A71
  color: #FFFFFF

@media (max-height:750px)
  .odx-modal__container
    max-height: 87vh
    overflow-y: scroll

.window-container
  padding-right: 12px
  padding-bottom: 12px
  display: flex
  gap: 8px
  flex-direction: column
  font-size: 13px

.window-header
  font-weight: 600
  font-size: 16px

.window-details
  background-color: $usability-blue
  padding: 8px 5px
  font-size: 16px
  font-weight: 600
  border-radius: 6px
  color: white
  text-decoration: none
  text-align: center

.odx-toast-container
  z-index: 99999

.add-button
  background-color: #0074ff !important
  &:hover
    background-color: #0066e0 !important

.download-button
  font-size: var(--odx-vertical-rythm-base-size)
  background-color: var(--odx-c-secondary) !important
  &:not(:has(> .odx-icon, > .odx-avatar))
    min-width: var(--odx-vertical-rythm-base-size)
    font-size: calc(var(--odx-vertical-rythm-base-size) * 0.5)
    padding-top: 6px
  &:hover
    background-color: var(--odx-c-secondary-hover) !important

.page-loader
  display: flex
  justify-content: center
  align-items: center
  margin: 45vh auto 0 auto

.modal-loader
  display: flex
  justify-content: center
  align-items: center

.full-height-table
  height: 95vh
  &__calibration
    height: 90vh
    .p-datatable-flex-scrollable, .p-datatable-flex-scrollable .p-datatable-scrollable-view, .p-datatable-flex-scrollable .p-datatable-scrollable-wrapper
      max-height: calc(100vh - 200px) !important

.odx-rail-navigation
  background-color: var(--odx-badge-contrast-color)
  height: 95%

  a
    color: white
    text-decoration: none

  .odx-icon
    font-size: calc(var(--odx-vertical-rythm-base-size)* 1.3334)

.form-field
  margin-bottom: 6px
  color: var(--odx-input-control-color)
  &__statistics
    width: 260px
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item
      max-width: none

    .p-calendar
      width: 100%
      margin-top: calc(var(--odx-vertical-rythm-base-size)* 0)
      margin-bottom: calc(var(--odx-vertical-rythm-base-size)* 0)
      min-height: calc(var(--odx-vertical-rythm-base-size)* 1.5)
      transition-delay: 0ms
      transition-duration: var(--odx-v-transition-duration)
      transition-property: background-color, color, outline-color
      transition-timing-function: var(--odx-v-transition-easing-fn)
      outline: var(--odx-v-outline-width) solid transparent
      outline-offset: calc(-1* var(--odx-v-outline-width))
      background-color: var(--odx-input-control-background-color)
      border-radius: var(--odx-v-border-radius-controls)
      display: flex
      outline-color: var(--odx-input-control-outline-color)

      .p-inputtext
        margin-top: calc(var(--odx-vertical-rythm-base-size)* 0)
        margin-bottom: calc(var(--odx-vertical-rythm-base-size)* 0)
        min-height: calc(var(--odx-vertical-rythm-base-size)* 1.5)
        transition-delay: 0ms
        transition-duration: var(--odx-v-transition-duration)
        transition-property: background-color, color, outline-color
        transition-timing-function: var(--odx-v-transition-easing-fn)
        outline: var(--odx-v-outline-width) solid transparent
        outline-offset: calc(-1* var(--odx-v-outline-width))
        background-color: var(--odx-input-control-background-color)
        border-radius: var(--odx-v-border-radius-controls)
        display: flex
        outline-color: var(--odx-input-control-outline-color)
        &:hover
          background-color: var(--odx-input-control-background-color-hover)
          outline-color: var(--odx-input-control-outline-color-hover)

  &-label
    overflow: clip
    text-overflow: ellipsis
    white-space: nowrap
  &__label
    padding-top: calc(var(--odx-vertical-rythm-base-size)* 0)
    padding-bottom: calc(var(--odx-vertical-rythm-base-size)* 0)
    line-height: calc(var(--odx-vertical-rythm-base-size)* 0.8334)
    font-size: calc(var(--odx-typography-base-size)*(1* 1 / var(--odx-typography-negative-font-scaling-factor)))
    -webkit-user-select: none
    user-select: none
    padding-left: calc(var(--odx-vertical-rythm-base-size)* 0.3334)
    flex: 1 1 auto
    display: block
    &-is-required
      color: var(--odx-c-error-text)
      font-size: calc(var(--odx-typography-base-size)* 1)
      margin-left: 2px
      vertical-align: top

.time-wrap
  width: 160px

.modal-form
  .p-dropdown
    line-height: inherit
    height: auto
    margin-top: calc(var(--odx-vertical-rythm-base-size)* 0)
    margin-bottom: calc(var(--odx-vertical-rythm-base-size)* 0)
    min-height: calc(var(--odx-vertical-rythm-base-size)* 1.5)
    transition-delay: 0ms
    transition-duration: var(--odx-v-transition-duration)
    transition-property: background-color, color, outline-color
    transition-timing-function: var(--odx-v-transition-easing-fn)
    outline: var(--odx-v-outline-width) solid transparent
    outline-offset: calc(-1* var(--odx-v-outline-width))
    background-color: var(--odx-input-control-background-color)
    border: none
    border-radius: var(--odx-v-border-radius-controls) !important
    display: flex
    outline-color: var(--odx-input-control-outline-color)
    &:hover
      background-color: var(--odx-input-control-background-color-hover)
      outline-color: var(--odx-input-control-outline-color-hover)
    &:focus-within
      background-color: var(--odx-c-focus)
      outline-color: var(--odx-c-focus-outline)

  .p-dropdown .p-dropdown-label
    height: auto !important
    margin-top: 0 !important
  .p-dropdown .p-inputtext
    background-color: transparent
    padding-left: 10px
  .p-dropdown .p-dropdown-panel .p-dropdown-header
    background-color: var(--odx-input-control-background-color) !important
  .p-dropdown-items-wrapper
    background-color: var(--odx-c-background-content)
    border-radius: var(--odx-v-border-radius)
    box-shadow: var(--odx-v-box-shadow-layer-1)
  .p-multiselect
    margin-top: calc(var(--odx-vertical-rythm-base-size)* 0)
    margin-bottom: calc(var(--odx-vertical-rythm-base-size)* 0)
    min-height: calc(var(--odx-vertical-rythm-base-size)* 1.5)
    transition-delay: 0ms
    transition-duration: var(--odx-v-transition-duration)
    transition-property: background-color, color, outline-color
    transition-timing-function: var(--odx-v-transition-easing-fn)
    outline: var(--odx-v-outline-width) solid transparent
    outline-offset: calc(-1* var(--odx-v-outline-width))
    background-color: var(--odx-input-control-background-color)
    border-radius: var(--odx-v-border-radius-controls)
    display: flex
    outline-color: var(--odx-input-control-outline-color)
    &:hover
      background-color: var(--odx-input-control-background-color-hover)
      outline-color: var(--odx-input-control-outline-color-hover)
    &:focus-within
      background-color: var(--odx-c-focus)
      outline-color: var(--odx-c-focus-outline)
  .p-multiselect-label
    padding: 0.6rem 0.6rem
  .p-overlay
    width: 100%
  .p-multiselect-items-wrapper
    max-height: 150px !important
  .p-multiselect .p-multiselect-label.p-placeholder
    height: auto !important
  .p-multiselect-item, .p-multiselect-representative-option
    max-width: none !important

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext
  background-color: var(--odx-input-control-background-color)
.p-checkbox .p-checkbox-box
  transition-delay: 0ms
  transition-duration: var(--odx-v-transition-duration)
  transition-property: background-color, color, outline-color
  transition-timing-function: var(--odx-v-transition-easing-fn)
  display: inline-flex
  align-items: center
  justify-content: center
  outline: var(--odx-v-outline-width) solid transparent
  outline-offset: calc(-1* var(--odx-v-outline-width))
  background-color: var(--odx-control-background-color)!important
  border: none !important
  border-radius: var(--odx-v-border-radius-controls) !important
  color: var(--odx-control-color-selected)
  outline-color: var(--odx-control-outline-color)
  padding: 1px
.p-multiselect-panel .p-checkbox
  margin-right: 1rem

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:hover
  background-color: var(--gray-50)

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover
  background-color: var(--gray-50)

.textfield
  position: relative
  width: 100%

.statistics
  display: flex
  gap: 10px

.draeger-card
  background-color: var(--odx-input-control-background-color-hover)
  box-shadow: 1px 1px 1px rgba(0, 0, 0, .2)
  display: flex
  position: relative
  flex-flow: column
  height: 100%
  &__header
    background-color: var(--odx-c-background-content)
    display: flex
    align-items: center
    padding-left: 16px
    cursor: default

  &__headline
    font-weight: 700
    font-size: 16px
    margin: 11px 0
    display: inline-block
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

@media not all and (pointer: coarse), (-ms-high-contrast: none)
  .draeger-card__header
    height: 48px

@media not all and (pointer: coarse), (-ms-high-contrast: none)
  .draeger-card__headline
    line-height: 48px

.column-filter
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item
    max-width: none !important

.board
  margin-bottom: 40px
  padding: 24px
  transition: .2s ease
  display: flex
  position: relative
  flex: 1 1 auto
  flex-wrap: wrap
  align-content: flex-start
  overflow: auto

  &__centered
    align-content: center
    align-items: center
    justify-content: center
    height: 100%
    display: flex
    flex-wrap: wrap

  &__box
    flex: 1 1 450px
    min-width: 450px
    height: 300px
    padding: 8px

  .filters
    width: 100%
    padding: 8px
    margin-bottom: 16px
    display: flex
    flex-wrap: wrap
    gap: 8px
    min-height: 60px

  .statistics-wrap
    flex: 1 1 auto

    .statistics-row
      display: flex
      flex-wrap: wrap
      flex: 1 1 auto

::-webkit-scrollbar-track
  background: rgba(0, 0, 0, 0)

::-webkit-scrollbar-thumb
  min-height: 48px
  border: 4px solid rgba(0, 0, 0, 0)
  border-radius: 8px
  background-clip: content-box

::-webkit-scrollbar-corner
  background-color: rgba(0, 0, 0, 0)

::-webkit-scrollbar
  width: 16px
  height: 16px
  border-radius: 8px

.odx-toast-item__content
  font-size: 20px
  .odx-icon
    margin-top: 0.3rem
  button
    .odx-icon
      margin-top: 0

.odx-toast-container
  padding-top: calc(var(--odx-toast-outer-offset)* 3)
