@import 'src/assets/styles/mixins'
@import 'src/assets/styles/variables'

.draeger-table
  display: block
  margin: 4px
  @media (min-width: 1139px)
    margin: 0 2.5rem

.p-datatable-scrollable-view
  height: calc(100vh - 169px)

  @media (min-width: 1139px)
    height: calc(100vh - 209px)

.pi
  color: $steelwhite-01

.p-datatable .p-datatable-tbody > tr.selected-row,
.p-datatable .p-datatable-tbody > tr.p-highlight
  background: $usability-blue !important
  outline: none !important

  &:focus
    outline: none !important

  &:hover
    background: $usability-blue !important

.p-selectable-row
  &:focus
    outline: none !important

.p-datatable-tbody
  background-color: var(--odx-c-background-content) !important

.p-datatable .p-datatable-scrollable-header
  background-color: var(--odx-c-background-content) !important

.p-datatable-scrollable-footer, .p-datatable-scrollable-header
  overflow: visible !important

.p-datatable-scrollable-body
  flex: initial !important

  @media (min-width: 300px)
    max-height: calc(100vh - 399px)

  @media (min-width: 800px)
    max-height: calc(100vh - 389px)

  @media (min-width: 1200px)
    max-height: calc(100vh - 379px)

  @media (min-width: 1600px)
    max-height: calc(100vh - 349px)

.p-datatable-flex-scrollable, .p-datatable-flex-scrollable .p-datatable-scrollable-view, .p-datatable-flex-scrollable .p-datatable-scrollable-wrapper
  max-height: calc( 100vh - 180px ) !important

.p-datatable .p-datatable-tbody > tr
  cursor: pointer
  background-color: rgba(33,45,56,.1)

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even),
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight,
.p-datatable .p-datatable-tbody > tr.p-highlight
  background-color: transparent

.p-datatable .p-datatable-tbody > tr
  &:hover
    background: var(--odx-c-separator) !important

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even):hover
  background-color: rgba(33,45,56,.2)

.p-datatable .p-datatable-tbody > tr > th.p-highlight
  background-color: $usability-blue !important

.p-datatable .p-datatable-thead
  padding-bottom: 8px
  background-color: var(--odx-c-background-content) !important
  border-bottom: 1px solid var(--odx-c-separator)

.p-datatable .p-datatable-thead > tr > th
  font-weight: bold
  border: none
  padding: 0.5rem 1rem !important
  align-items: flex-start !important
  white-space: nowrap

.p-datatable .p-datatable-thead > tr > th p-columnfilter
  width: 100%

.p-datatable .p-datatable-thead > tr > th:last-child
  border: none

.p-datatable-scrollable-body>table>.p-datatable-tbody>tr:first-child>td
  border-top: none

.p-datatable .p-datatable-tbody > tr > td
  border-top: none
  border-bottom: 1px solid var(--odx-c-separator)
  padding: 0.4rem 1rem
  white-space: normal !important

.date-time-col
  @media (min-width: 300px)
    max-width: 100px

  @media (min-width: 800px)
    max-width: 100px

  @media (min-width: 1200px)
    max-width: 100px

  @media (min-width: 1600px)
    width: 380px

.date-col
  @media (min-width: 300px)
    max-width: 100px

  @media (min-width: 800px)
    max-width: 100px

  @media (min-width: 1200px)
    max-width: 100px

  @media (min-width: 1600px)
    width: 300px

.p-fluid .p-inputtext
  transition-delay: 0ms
  transition-duration: var(--odx-v-transition-duration)
  transition-property: background-color, color, outline-color
  transition-timing-function: var(--odx-v-transition-easing-fn)
  outline: var(--odx-v-outline-width) solid transparent
  outline-offset: calc(-1* var(--odx-v-outline-width))
  background-color: var(--odx-input-control-background-color)
  border: none
  border-radius: var(--odx-v-border-radius-controls)
  outline-color: var(--odx-input-control-outline-color)
  height: 25px
  &:hover
    background-color: var(--odx-input-control-background-color-hover)
    outline-color: var(--odx-input-control-outline-color-hover)

.p-inputtext:enabled:focus
  box-shadow: none
  border-color: $usability-blue

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group
  border-right: 0px !important

.p-datepicker-multiple-month
  transform: translate(15.5%, 0%) !important

.p-datepicker
  z-index: 9999 !important
  border-radius: 0 !important

.p-datepicker .p-datepicker-header
  border-radius: 0 !important

.p-calendar
  height: 25px
  width: 100%
  margin-top: calc(var(--odx-vertical-rythm-base-size)* 0)
  margin-bottom: calc(var(--odx-vertical-rythm-base-size)* 0)
  transition-delay: 0ms
  transition-duration: var(--odx-v-transition-duration)
  transition-property: background-color, color, outline-color
  transition-timing-function: var(--odx-v-transition-easing-fn)
  outline: var(--odx-v-outline-width) solid transparent
  outline-offset: calc(-1* var(--odx-v-outline-width))
  background-color: var(--odx-input-control-background-color)
  border-radius: var(--odx-v-border-radius-controls)
  display: flex
  outline-color: var(--odx-input-control-outline-color)

  .p-inputtext
    margin-top: calc(var(--odx-vertical-rythm-base-size)* 0)
    margin-bottom: calc(var(--odx-vertical-rythm-base-size)* 0)
    transition-delay: 0ms
    transition-duration: var(--odx-v-transition-duration)
    transition-property: background-color, color, outline-color
    transition-timing-function: var(--odx-v-transition-easing-fn)
    outline: var(--odx-v-outline-width) solid transparent
    outline-offset: calc(-1* var(--odx-v-outline-width))
    background-color: var(--odx-input-control-background-color)
    border-radius: var(--odx-v-border-radius-controls)
    display: flex
    outline-color: var(--odx-input-control-outline-color)
    &:hover
      background-color: var(--odx-input-control-background-color-hover)
      outline-color: var(--odx-input-control-outline-color-hover)

  .p-button
    background: $steelgray-06
    border: 1px solid $steelgray-06
    border-radius: 0px

  .p-button:enabled:hover
    background: $steelgray-06
    border-color: $steelgray-06

  .p-button:enabled:focus
    box-shadow: none
    border-color: $usability-blue

  .p-button:enabled:hover
    border: 1px solid $steelgray-05

  .p-datepicker
    min-width: 300px !important

.p-inputtext::placeholder
  color: $steelgray-02

.p-datepicker:not(.p-datepicker-inline)
  background: $steelgray-07

.p-datepicker table th
  color: $steelwhite-01
  text-align: center

.p-datepicker table td
  padding: 0.2rem

.p-datepicker table td > span
  color: $steelwhite-01
  border-radius: 0px

.p-datepicker table td.p-datepicker-today > span
  background: $steelgray-05
  color: $steelwhite-01

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover
  background: $usability-blue
  color: $steelwhite-01

.p-datepicker table td > span.p-highlight
  background: $usability-blue
  color: $steelwhite-01

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header
  background: $steelgray-03

.p-datepicker .p-datepicker-header
  padding: 0.2rem
  color: $steelwhite-01
  background: $steelwhite-01
  font-weight: 500
  border-bottom: none

.p-datepicker .p-datepicker-header .p-datepicker-title select
  background: $steelgray-03
  color: $steelwhite-01

.p-datepicker .p-datepicker-header .p-datepicker-prev, .p-datepicker .p-datepicker-header .p-datepicker-next
  background: $steelgray-04
  border-radius: 0px

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover
  background: $steelgray-04
  color: $steelwhite-01

.p-datepicker .p-datepicker-header .p-datepicker-next:focus
  box-shadow: none

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus
  box-shadow: none

.p-datepicker .p-timepicker
  border: none
  padding: 0px
  span
    color: $steelwhite-01
    font-size: 14px

  button
    height: 1.5rem

  button:focus
    box-shadow: none

  button:enabled:hover
    background: $steelgray-05
    border-color: $steelgray-05
    color: $steelgray-04

.p-datepicker table td.p-datepicker-today > span.p-highlight
  background: $steelgray-05
  color: var(--odx-c-text)

.p-datatable .p-sortable-column:focus
  box-shadow: none

.p-datatable .p-sortable-column:not(.p-highlight):hover
  color: var(--odx-c-text)
  background: transparent

.p-datatable .p-sortable-column.p-highlight
  color: var(--odx-c-text)
  background: transparent !important

.p-datatable .p-sortable-column.p-highlight:hover
  color: var(--odx-c-text)

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon
  color: var(--odx-c-text)

.pi-sort-amount-up-alt:before
  content: "\e933"

.pi-sort-amount-down:before
  content: "\e930"

.pi-sort-alt:before
  color: $steelwhite-01
  content: "\e930"

.pi-times:before
  color: $steelwhite-01

.report-center-table-ms
  div
    border: none

.p-multiselect-label .p-placeholder
  color: $steelgray-02

.p-multiselect-empty-message
  color: $steelwhite-01 !important

.p-multiselect-panel
  width: 100% !important
  background-color: transparent

.p-multiselect-panel .p-multiselect-header
  background-color: var(--odx-c-background-content) !important
  border-radius: 0px !important

.p-multiselect-items-wrapper
  background-color: var(--odx-c-background-content) !important

.p-checkbox .p-checkbox-box
  background-color: var(--odx-control-background-color) !important
  border-radius: 0px !important

.p-multiselect-panel .p-multiselect-items .p-multiselect-item
  color: $steelwhite-01
  padding: 0.5rem 0.5rem 0.5rem 1rem
  text-overflow: ellipsis
  white-space: break-spaces

  .p-multiselect-representative-option
    overflow: hidden !important
    max-width: 120px
    font-weight: 500

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight
  color: $steelwhite-01 !important
  background: transparent !important
  box-shadow: none !important

  &:hover
    background: $usability-blue !important

  &:focus
    box-shadow: none !important

.p-multiselect-custom-close-btn
  position: absolute !important
  right: 14px
  z-index: 10

  &:hover
    background-color: transparent !important
    color: transparent !important

  &:focus
    box-shadow: unset !important

.ml-7
  margin-left: 7px

.p-multiselect-empty-message
  visibility: hidden

.multiselect-custom-error-wrapper
  position: absolute
  bottom: 0
  width: 100%
  z-index: 1
  background: $steelgray-05 !important
  li
    padding: 0.5rem 1rem
    color: $steelwhite-01
    background: transparent

.p-datatable .p-sortable-column.p-highlight,
.selected-sort-column
  background-color: var(--odx-c-background-content) !important

.p-datatable span.p-sortable-column
  width: 100%
  height: 100%

.table-header-right-wrapper:last-child
  width: 0px

.deselected-sort-column
  background-color: var(--odx-c-background-content) !important
  &:last-child
    width: 0px

.p-fluid .p-multiselect
  transition-delay: 0ms
  transition-duration: var(--odx-v-transition-duration)
  transition-property: background-color, color, outline-color
  transition-timing-function: var(--odx-v-transition-easing-fn)
  outline: var(--odx-v-outline-width) solid transparent
  outline-offset: calc(-1* var(--odx-v-outline-width))
  background-color: var(--odx-input-control-background-color)
  border: none
  border-radius: var(--odx-v-border-radius-controls)
  outline-color: var(--odx-input-control-outline-color)
  display: flex
  height: 25px !important
  &:hover
    background-color: var(--odx-input-control-background-color-hover)
    outline-color: var(--odx-input-control-outline-color-hover)

.p-datatable-scrollable-header-table
  position: relative

.table-header-right-wrapper
  height: 100%
  position: absolute
  right: -16px
  top: 0
  width: 16px

.z-index-1
  z-index: 1

table colgroup col
  width: 100%

.multiSelect-overflow
  min-width: 170px !important

.p-datatable-reorder-indicator-up,.p-datatable-reorder-indicator-down
  z-index: 1

.p-datatable-resizable .p-datatable-thead > tr > th
  white-space: normal !important

.draeger-table ::-webkit-scrollbar:vertical
  border: 1px solid var(--odx-c-background-content) !important
  border-left: none
  background-color: var(--odx-c-background-content)

.draeger-table ::-webkit-scrollbar:horizontal
  border: 1px solid var(--odx-c-background-content) !important
  background-color: var(--odx-c-background-content)

.draeger-table .p-multiselect .p-multiselect-label
  margin-top: -5px

.draeger-table .p-dropdown
  height: 25px
  margin-top: calc(var(--odx-vertical-rythm-base-size)* 0)
  margin-bottom: calc(var(--odx-vertical-rythm-base-size)* 0)
  transition-delay: 0ms
  transition-duration: var(--odx-v-transition-duration)
  transition-property: background-color, color, outline-color
  transition-timing-function: var(--odx-v-transition-easing-fn)
  outline: var(--odx-v-outline-width) solid transparent
  outline-offset: calc(-1* var(--odx-v-outline-width))
  background-color: var(--odx-input-control-background-color)
  border: none
  border-radius: var(--odx-v-border-radius-controls) !important
  display: flex
  outline-color: var(--odx-input-control-outline-color)
  &:hover
    background-color: var(--odx-input-control-background-color-hover)
    outline-color: var(--odx-input-control-outline-color-hover)
  .p-inputtext
    background-color: none
  .p-dropdown-label
    margin-top: 0
    padding-top: 5px

  .p-dropdown-clear-icon
    margin-top: -6px

.p-datatable-scrollable .p-datatable-thead>tr>th
  display: flex
  flex-wrap: nowrap
  width: 100%

.p-datatable-scrollable .p-datatable-thead, .p-datatable-scrollable .p-datatable-tbody, .p-datatable-scrollable .p-datatable-tfoot
  display: block

.p-datatable-scrollable .p-datatable-thead>tr, .p-datatable-scrollable .p-datatable-tbody>tr, .p-datatable-scrollable .p-datatable-tfoot>tr
  display: flex
  flex-wrap: nowrap
  width: 100%

p-datatable-scrollable .p-datatable-thead>tr>th, .p-datatable-scrollable .p-datatable-tbody>tr>td, .p-datatable-scrollable .p-datatable-tfoot>tr>td 
  display: flex
  flex: 1 1 0
  align-items: center

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight
  background: #E3F2FD
  border-color: #E3F2FD
  color: #495057

.p-paginator
  background-color: #465A71 !important
  color: #FFFFFF !important
  justify-content: right !important
  padding: 0.5rem 1rem
  border-radius: 0

.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last
  background-color: transparent
  border: 0 none
  color: #6c757d
  min-width: 2.357rem
  height: 2.357rem
  margin: 0.143rem
  transition: box-shadow 0.2s
  border-radius: 3px

  .p-icon-wrapper
    color: #FFFFFF

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover
  background: #c7e2f563
  border-color: transparent
  color: #495057

.p-paginator .p-dropdown
  margin-left: 0.5rem
  margin-right: 0.5rem

  .p-inputtext
    padding: 0.3rem 0.5rem

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus
    background: none

.p-paginator .p-paginator-pages
  display: flex
  gap: 10px
  .p-paginator-element
    border-radius: 3px
    padding: 5px

  .p-paginator-element:hover:not(.p-highlight)
    background-color: #c7e2f563

.draeger-table

  .p-dropdown-header
    background-color: var(--odx-input-control-background-color)
  .p-dropdown-items-wrapper
    background-color: var(--odx-c-background-content) !important

  .p-dropdown-panel .p-dropdown-items
    background-color: var(--odx-c-background-content) !important

  .p-fluid .p-dropdown .p-inputtext
    padding: 0.5rem 0.5rem !important

  .p-paginator .p-dropdown .p-inputtext
    padding: 0.5rem 0.5rem !important

  .p-datatable-wrapper
    background-color: var(--odx-c-background-content)
