.p-dropdown
  border-radius: 0px !important
  line-height: 1
  height: 32px

  .p-placeholder
    color: $draeger-dropdown-placeholder-color !important

  .p-inputtext
    outline: none

.p-dropdown .p-dropdown-panel
  min-width: 50%
  width: 100% !important
  max-width: 328px

  .p-dropdown-header
    background-color: var(--odx-input-control-background-color) !important
    border-radius: 0px !important

@include firefox-only
  .p-dropdown
    top: 7px

.p-dropdown
  border-radius: 0px !important
  border: 1px solid rgba($steelgray-01, .5)
  background-color: $steelgray-04

  .p-dropdown-label
    border: none
    height: 22px
    margin-top: 3px

.p-multiselect-label .p-placeholder
  color: $steelgray-02

.p-dropdown:enabled:focus
  box-shadow: none
  border-color: $usability-blue
  border-radius: 0px !important

.p-dropdown:enabled:hover
  border-color: $usability-blue
  border-radius: 0px !important

.p-dropdown:not(.p-disabled):hover
  border-color: rgba($steelgray-01, .5)

.p-dropdown-items-wrapper
  background: $steelgray-05
  border-radius: 0px !important

.p-dropdown-panel .p-dropdown-items
  background-color: var(--odx-c-background-content)
  border: none
  border-radius: 0px !important

  .p-dropdown-item
    border-radius: 0px !important

  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover
    background-color: var(--gray-50)
    outline-color: var(--odx-input-control-outline-color-hover)
    border-radius: 0px !important

.p-dropdown:not(.p-disabled).p-focus
  box-shadow: none
  border-radius: 0px !important

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight
  background-color: #0074FF

.p-dropdown-panel .p-dropdown-items:not(.p-dropdown-virtualscroll)
  padding: 0.5rem 0

.custom-dropdown-icon
  color: $steelwhite-01
  font-size: 16px

.ui-multiselected-empty-token
  color: $draeger-dropdown-placeholder-color !important

.p-multiselect-panel
  white-space: nowrap
  .p-multiselect-representative-option
    overflow: hidden
    text-overflow: ellipsis
  .p-checkbox
    margin-right: 0.5rem

p-dropdown.ng-dirty.ng-invalid.ng-untouched > .p-dropdown
  border-color: rgba($steelgray-01, .5)

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext
  background-color: $steelgray-04
  border: none
  border-radius: 0px
  color: $steelwhite-01

.multiselect-filter
  width: 100%
  height: 32px !important
  .p-inputtext
    padding: 0.5rem 0.5rem

.multiselect-filter-user
  .p-multiselect-panel
    max-width: 329px

.p-multiselect
  background-color: $steelgray-05
  border: none
  border-radius: 0
  height: 32px !important

  .p-multiselect-item
    font-weight: 500

.p-multiselect .p-multiselect-label.p-placeholder
  color: $steelgray-02
  height: 32px !important

.p-dropdown-items
  margin: 0
  padding: 0
  list-style-type: none

.p-dropdown-panel .p-dropdown-items .p-dropdown-item
  margin: 0
  padding: 0.5rem 1rem
  border: 0 none
  transition: box-shadow 0.2s
  font-weight: 500

.draeger-table
  .p-fluid .p-dropdown
    display: flex

    .p-inputtext
      padding: 0.3rem 0.5rem

    .p-dropdown-clear-icon
      color: #fff

.p-multiselect-panel .p-multiselect-items:not(.p-multiselect-virtualscroll)
  padding: 0.5rem 0
  margin-bottom: 0

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus
  background-color: var(--gray-50)

.p-dropdown-filter
  .p-inputtext:enabled:hover
    border: 0px !important

.p-overlay
  max-width: min-content

.p-dropdown-empty-message
  padding-left: 5px

.map-dropdown-filter
  width: 200px

.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group
  background-color: transparent
  padding-left: 10px
  font-weight: 700
