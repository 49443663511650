$usability-blue: #0074FF
$usability-blue-02: #0069E6
$usability-blue-04: #2F8CFB

$steelgray-00: #DAE0E7
$steelgray-01: #96AABE
$steelgray-02: #6B8199
$steelgray-03: #586d85
$steelgray-04: #465A71
$steelgray-05: #3A4C61
$steelgray-06: #2D3C4C
$steelgray-07: #212D38
$steelgray-08: #0F1419
$steelgray-09: #475A70
$steelwhite-01: #FFFFFF
$steelblack-01: #000000

$high-vis-red-01: #E6141E

$high-vis-green-01: #8cf032

$high-vis-yellow-01: #FFB200

$shadow-color: #1D2936
$tooltip-color: rgba(15,20,25,.99999)

$phone-only: 599px
$tablet-portrait-up: 600px
$tablet-landscape-up: 900px
$desktop-up: 1200px
$big-desktop-up: 1800px

$phone-only-table-padding: 360px
$tablet-table-padding: 450px
$desktop-up-table-padding: 340px
$big-desktop-up-table-padding: 320px

$snackbar-success-border: #7fee1a
$snackbar-success-color: rgba(127,238,26,.3)

$snackbar-warning-border: #e6141e
$snackbar-warning-color: rgba(230,20,30,.3)

$draeger-datepicker-color: #3a4c61
$draeger-datepicker-content-color: #465a71

$draeger-timeline-scrollbar-track-color: #3E4F5F
$draeger-timeline-scrollbar-thumb-color: #293849

$draeger-dropdown-placeholder-color: #758ba2
$draeger-black-color: #000000
$draeger-widget-text-color: #758BA2
$draeger-widget-footer-text-color: #81B4EB
$draeger-widget-hover-btn-color: #0066e0

// User color
$role-da: #8AF9DA
$role-ca: #FFC287
$role-e: #D6ECA9
$role-mro: #FFA3CD
