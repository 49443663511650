@import 'src/assets/styles/variables'

@mixin for-size($size)
  @if $size == phone-only
    @media (max-width: $phone-only)
      @content
  @else if $size == tablet-portrait-up
    @media (min-width: $tablet-portrait-up)
      @content
  @else if $size == tablet-landscape-up
    @media (min-width: $tablet-landscape-up)
      @content
  @else if $size == desktop-up
    @media (min-width: $desktop-up)
      @content
  @else if $size == big-desktop-up
    @media (min-width: $big-desktop-up)
      @content

  
@mixin firefox-only 
  @at-root 
    @-moz-document url-prefix() 
      @content
      
    
@mixin tabs-wrapper
  display: flex
  flex-direction: column
  justify-content: space-between

@mixin value-ellipsis
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  &::focus
    text-overflow: clip


